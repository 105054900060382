@font-face {
  font-family: 'Metropolis';
  src: url(./assets/fonts/Metropolis-Regular.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url(./assets/fonts/Metropolis-SemiBold.otf) format('opentype');
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Metropolis', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100% !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  scroll-behavior: smooth;
}

.slider-thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 4px solid #f3532e;
  height: 12px;
  width: 12px;
  border-radius: 16px;
  background: #ffffff;
  cursor: pointer;
}

.slider-thumb::-webkit-slider-thumb:hover {
  -webkit-appearance: none;
  border: 4px solid #f3532e;
  height: 12px;
  width: 12px;
  border-radius: 16px;
  background: #ffffff;
  cursor: pointer;
}

:focus {
  outline-color: transparent;
  outline-style: none;
}

.override-maps-jo > div > .gm-style > div > .gm-style-moc > .gm-style-mot {
  font-size: 16px !important;
}

.override-bottom-sheet-jo > div > [data-rsbs-header]::before {
  content: none;
}
.override-bottom-sheet-jo > div > [data-rsbs-header] {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}
.override-bottom-sheet-so > div > [data-rsbs-scroll] > [data-rsbs-content] {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  overflow: hidden;
}

.override-bottom-sheet-so-tracking > div > [data-rsbs-header]::before {
  content: none;
}
.override-bottom-sheet-so-tracking > div > [data-rsbs-header] {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

.override-hjo-form-modal {
  position: relative;
  z-index: 100;
}
